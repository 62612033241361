// @import '~bootstrap-css-only/css/bootstrap.min.css';
// @import 'assets/styles/Antd/antd.cleanui';
// // @import 'assets/styles/CleanUI/cleanui';
// // @import 'assets/styles/Bootstrap/bootstrap.cleanui';
// // @import 'assets/styles/Chartist/chartist.cleanui';
// // @import 'assets/styles/Nprogress/nprogress.cleanui';
// // @import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
// // @import '~c3/c3.min.css';
.text-primary{
  color:#57a9dc !important;
}
.ant-card {
  box-shadow:0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)!important;
  .ant-card-head{
    background:#57a9dc;
    color:#fff;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.25rem;	
    .ant-card-head-title{
        padding:14px 0px;
    }
    .ant-btn{
      background: transparent;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
      border: 1px solid white;
    }
  }
}

.ant-btn-primary {
  color: #fff;
  background: #57a9dc;
  border-color: #57a9dc;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn:not(.ant-btn-icon-only) {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #57a9dc;
  border-color: #57a9dc;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.flex-flow-column{
  flex-flow:column !important; ;
}

.highlight {
  background-color: #f2f4f8;
}
.utils__content {
  max-width: 100%;
}
.site-card-wrapper {
  background: #ececec;
  padding: 30px;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
  background:transparent !important;
}
.ant-menu-submenu-popup {
.ant-menu-sub{
background:#57a9dc !important;
.ant-menu-item {
  a{
  color: rgba(255,255,255,0.8) !important;
}
&.ant-menu-item-active{
  a{
    color: rgba(255,255,255,1) !important;
  }
}
  
}

}
}
body .ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background:rgba(116, 187, 66, 0.5) !important;
}

.ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    width: 120px;
  }
.ant-form-item-label{
  label{
font-weight: 600;
  }
}
  

.disabled-form{
  .ant-form-item-control-input-content{
    .ant-input[disabled]{
      color:rgba(0, 0, 0, 0.6);
    }
  }
}


.form-item-flex-col{
  .ant-form-item-control{
    width: 100%;
    max-width: 100%;
  }
  .ant-form-item-control-input-content{
    display:flex;
    flex-direction: column;
    align-items: center;
    .ant-upload-picture-card-wrapper{
      display:flex;
      justify-content:center;
    }
  }
}

.form-full-width-field{
  width:100%;
  .ant-form-item-control{
    width:100%;
    max-width: 100%;
  }
}

.customizeInput{
  .ant-input-affix-wrapper {
    border: 0px !important;
}
}

.ant-dropdown-menu-item{
  cursor: default !important;
}